import BackgroundPage from "../components/BackgroundPage";
import {Box, Typography} from "@mui/material";
import OptionButton from "../components/OptionButton";
import {ReactComponent as Beginer} from "../assets/beginer.svg";
import {ReactComponent as BeginerActive} from "../assets/beginerActive.svg";
import {ReactComponent as Advance} from "../assets/advance.svg";
import {ReactComponent as AdvanceActive} from "../assets/advanceActive.svg";
import {ReactComponent as Pro} from "../assets/pro.svg";
import {ReactComponent as ProActive} from "../assets/proActive.svg";
import {useState} from "react";

const ExperiencePage = ({nextPage, setUserData, userData, prvPage, percentage}) => {
  const [nextActive, setNextActive] = useState(userData.experience !== "");

  const handleChange = (experience) => {
    setUserData(prv => {
      return {
        ...prv,
        experience: experience
      }
    });
    setNextActive(true);
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={nextActive} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/timePageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>מה הניסיון שלך באימוני משקולות?</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>זה עוזר לנו ליצור את תוכנית האימונים מותאמת אישית לך</Typography>
          </Box>
          <OptionButton height={"90px"} onClick={() => handleChange("BEGINNER")} active={() => userData.experience === "BEGINNER"} icon={<Beginer/>} activeIcon={<BeginerActive/>} text={<Box>
            <Typography sx={{fontSize: "20px", fontWeight: 700, fontFamily: "Assistant"}}>ללא ניסיון \ מתחיל</Typography>
            <Typography sx={{fontSize: "16px", fontWeight: 500, fontFamily: "Assistant"}}>מתחיל להתאמין בחדר כושר / כבר מתאמן בחדר כושר כמספר חודשים</Typography>
          </Box>}/>
          <OptionButton height={"90px"} onClick={() => handleChange("BEGINNER_ADVANCE")} active={() => userData.experience === "BEGINNER_ADVANCE"} icon={<Advance/>} activeIcon={<AdvanceActive/>} text={
            <Box>
              <Typography sx={{fontSize: "20px", fontWeight: 700, fontFamily: "Assistant"}}>בינוני \ מתקדם</Typography>
              <Typography sx={{fontSize: "16px", fontWeight: 500, fontFamily: "Assistant"}}>כבר מתאמן בחדר כושר מעל שנה, ביצעתי תרגילי כוח כגון סקוואט/דדליפט</Typography>
            </Box>
          }/>
          <OptionButton height={"90px"} onClick={() => handleChange("ADVANCE")} active={() => userData.experience === "ADVANCE"} icon={<Pro/>} activeIcon={<ProActive/>} text={
            <Box>
              <Typography sx={{fontSize: "20px", fontWeight: 700, fontFamily: "Assistant"}}>מקצוען</Typography>
              <Typography sx={{fontSize: "16px", fontWeight: 500, fontFamily: "Assistant"}}>מתאמן בחדר כושר כבר מספר שנים, טכניקת אימון טובה ושליטה בכל התרגילים</Typography>
            </Box>
          } />
        </BackgroundPage>
      </Box>
  );
};

export default ExperiencePage;
