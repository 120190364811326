import BackgroundPage from "../components/BackgroundPage";
import {Box, Typography} from "@mui/material";
import "../pickercss.css";
import React from "react";
import Wheel from "../components/Wheel";
import "../components/Wheel.css";

const TrainDaysPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const onChange = (value) => {
    setUserData(prv => {
      return {
        ...prv, trainDays: value > 0 ? value : 6 - Math.abs(value)
      }
    });
  };

  function formateDate(_relative, absolute) {
    return _relative + 1;
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={userData.targets.length > 0} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/trainDaysPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>כמה פעמים בשבוע אתה יכול להתאמן ?</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>אל תדאג, אתה תמיד יכול לשנות את מספר האימונים בשבוע</Typography>
          </Box>
          <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div
                style={{
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#000",
                }}
            >
              <div style={{width: 150, height: "50%" }}>
                <Wheel
                    loop
                    initIdx={2}
                    length={6}
                    setValue={formateDate}
                    onChange={onChange}
                />
              </div>
            </div>
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default TrainDaysPage;
