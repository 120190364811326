import {useState} from "react";
import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import OptionButton from "../components/OptionButton";
import {ReactComponent as Chest} from "../assets/chest.svg";
import {ReactComponent as ChestActive} from "../assets/chestActive.svg";
import {ReactComponent as Arms} from "../assets/arms.svg";
import {ReactComponent as ArmsActive} from "../assets/armsActive.svg";
import {ReactComponent as Back} from "../assets/back.svg";
import {ReactComponent as BackActive} from "../assets/backActive.svg";
import {ReactComponent as Shoulders} from "../assets/shoulders.svg";
import {ReactComponent as ShouldersActive} from "../assets/shouldersActive.svg";
import {ReactComponent as ArmsFront} from "../assets/armsFront.svg";
import {ReactComponent as ArmsFrontActive} from "../assets/armsFrontActive.svg";
import {ReactComponent as ArmsBack} from "../assets/armsBack.svg";
import {ReactComponent as ArmsBackActive} from "../assets/armsBackActive.svg";

import {ReactComponent as LegsBack} from "../assets/legsBack.svg";
import {ReactComponent as LegsBackActive} from "../assets/legsBackActive.svg";
import {ReactComponent as LegsFront} from "../assets/legsFront.svg";
import {ReactComponent as LegsFrontActive} from "../assets/legsFrontActive.svg";

import {ReactComponent as Buttock} from "../assets/buttock.svg";
import {ReactComponent as ButtockActive} from "../assets/buttockActive.svg";
import {ReactComponent as Twines} from "../assets/twins.svg";
import {ReactComponent as TwinesActive} from "../assets/twinsActive.svg";
import {ReactComponent as Abs} from "../assets/abs.svg";
import {ReactComponent as AbsActive} from "../assets/absActive.svg";

const BodyPartSpecificPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [nextActive, setNextActive] = useState(userData.bodyPartSpecific.length > 0);

  function findArrayElementByTitle(array, title) {
    return array.some(item => item === title);
  }

  const modifyTargets = (target) => {
    setUserData(prv => {
      let newTargets = [...prv.bodyPartSpecific];

      if (newTargets.length < 2 || findArrayElementByTitle(newTargets, target)) {

        if (findArrayElementByTitle(newTargets, target)) {
          newTargets = newTargets.filter((item) => item !== target);
        } else {
          newTargets.push(target);
        }
      }

      console.log(newTargets)
      setNextActive(newTargets.length > 0);

      return {
        ...prv,
        bodyPartSpecific: newTargets
      }
    })
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={nextActive} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/timePageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>בחר את הדגש החשוב לך</Typography>
          </Box>
          {/*<Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>*/}
          {/*  <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>תחליט על איזה חלק בגוף תרצה לשים דגש בתוכנית האימונים שלך</Typography>*/}
          {/*</Box>*/}
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#EDEDED", fontSize: "14px", fontWeight: 600, fontFamily: "Assistant"}}>(ניתן לבצע עד 2 בחירות)</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("CHEST")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "CHEST")} icon={<Chest/>} activeIcon={<ChestActive/>} text={"חזה"}/>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("ARMS")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "ARMS")} icon={<Arms/>} activeIcon={<ArmsActive/>} text={"ידיים"}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("BACK")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "BACK")} icon={<Back/>} activeIcon={<BackActive/>} text={"גב"}/>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("SHOULDERS")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "SHOULDERS")} icon={<Shoulders/>} activeIcon={<ShouldersActive/>} text={"כתפיים"}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("FRONT_ARM")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "FRONT_ARM")} icon={<ArmsFront/>} activeIcon={<ArmsFrontActive/>} text={"יד קידמית"}/>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("LOWER_ARM")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "LOWER_ARM")} icon={<ArmsBack/>} activeIcon={<ArmsBackActive/>} text={"יד אחורית"}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("UPPER_LEGS")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "UPPER_LEGS")} icon={<LegsFront/>} activeIcon={<LegsFrontActive/>} text={"רגל קדמית"}/>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("LOWER_LEGS")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "LOWER_LEGS")} icon={<LegsBack/>} activeIcon={<LegsBackActive/>} text={"רגל אחורית"}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("BUT")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "BUT")} icon={<Buttock/>} activeIcon={<ButtockActive/>} text={"ישבן"}/>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("CALVES")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "CALVES")} icon={<Twines/>} activeIcon={<TwinesActive/>} text={"תאומים"}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <OptionButton height={"34px"} width={"40%"} onClick={() => modifyTargets("ABS")} active={() => findArrayElementByTitle(userData.bodyPartSpecific, "ABS")} icon={<Abs/>} activeIcon={<AbsActive/>} text={"בטן"}/>
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default BodyPartSpecificPage;
