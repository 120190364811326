import {Box, CircularProgress, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import {useEffect, useState} from "react";
import {createUser} from "../service/OnboardingService";

const LoadingPage = ({setPage, nextPage, setUserData, userData, prvPage, percentage}) => {
  const [progress, setProgress] = useState(10);
  const [finish, setFinish] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (finish === true) {
      createUser(userData)
          .then(() => nextPage(1))
          .catch(() => {
            setPage(-1);
          })
    }
  }, [finish]);

  useEffect(() => {

    const timer = setInterval(() => {
        setProgress((prevProgress) =>  {

          if (prevProgress >= 75) {
            setFinish(true);
          }

          return prevProgress >= 55 ? prevProgress+1 : prevProgress+2 ;
        });

    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);


  function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress size={150} variant="determinate" {...props} />
          <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
          >
            <Typography sx={{fontSize: "28px", fontWeight: 700}} variant="caption" component="div" color="white">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
    );
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage disableButton={true} nextPage={nextPage} nextActive={userData.targets.length > 0} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/targetPageImage.png"}>
          <Box sx={{mt: "40%",display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Box sx={{position: 'relative', display: 'inline-flex' }}>
            <CircularProgressWithLabel value={progress} />
          </Box>
          </Box>
          <Box sx={{mt: "40%", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>מעבדים את הנתונים</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>אנחנו יוצרים לך תוכנית אימונים המתאימה ביותר עבורך</Typography>
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default LoadingPage;
