import {Box, Button, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import {ReactComponent as TimeLine} from '../assets/timeLineGraph.svg';
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const ProgramReadyPage = ({nextPage, userData, prvPage, percentage}) => {

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage disableButton={true} showBack={false} nextActive={true} nextPage={nextPage} percentage={percentage} prvPage={prvPage}>
          <Box sx={{mt: "12px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>התוכנית מוכנה!</Typography>
          </Box>
          <Box sx={{mt: "12px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#07BBA6", fontSize: "30px", fontWeight: 700, fontFamily: "Assistant"}}>4 שבועות לתוצאות!</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "18px", fontWeight: 600, fontFamily: "Assistant"}}>עיבדנו את כל הנתונים שלך ומצאנו את התוכניות במדויקת עבורך</Typography>
          </Box>
          <Box sx={{mt: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <TimeLine/>
          </Box>
          {/*<Box sx={{mt: "12px", display: "flex", justifyContent: "center"}}>*/}
          {/*  <Box sx={{width: "90%", border: "1px solid #292929", borderRadius: "8px 8px 0px 0px"}}>*/}
          {/*    <Box sx={{mt: "14px", mb: "14px", display: "flex"}}>*/}
          {/*      <Typography sx={{mr: "8px", maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#2EA08C", fontSize: "18px", fontWeight: 600, fontFamily: "Assistant"}}>תוכנית אימונים {userData.trainDays} פעמים בשבוע</Typography>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
          <Box sx={{mt: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Button
                onClick={nextPage}
                endIcon={<ArrowLeftIcon
                    sx={{textAlign: "center", width: "32px", height: "24px", mt: "2px"}} />}
                sx={{mt: "24px",borderRadius: "48px", fontSize: "17px", '&:hover': {
                    backgroundColor: "primary.light",
                  },
                  backgroundColor: "primary.light", textAlign: "center", color: "#FFF", width: "80%"}}>
              <Typography sx={{mr: "12px"}}>קדימה בואו נתחיל</Typography>
            </Button>
          </Box>
        </BackgroundPage>
      </Box>);
};

export default ProgramReadyPage;
