import BackgroundPage from "../components/BackgroundPage";
import {Box, Typography} from "@mui/material";
import {ReactComponent as DoneSign} from "../assets/doneSign.svg";
import {ReactComponent as CrownFream} from "../assets/crownFream.svg";
import {ReactComponent as GoogleIcon} from "../assets/google.svg";
import {ReactComponent as AppleIcon} from "../assets/apple.svg";

const FinishScreen = ({userData}) => {

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `progress-workout-1.4.apk`;
    link.href = "https://progress-data.s3.eu-central-1.amazonaws.com/android/progress-workout-1.4.apk";
    link.click();
  };

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage disableButton={true} showProgress={false} showBack={false} backgroundImage={"/images/finishImage.png"}>
          {/*<Box sx={{display: "flex", justifyContent: "center"}}>*/}
          {/*  <Typography sx={{color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>מגדר?</Typography>*/}
          {/*</Box>*/}
          {/*<Box sx={{display: "flex", justifyContent: "center"}}>*/}
          {/*  <Typography sx={{maxWidth: "70%", color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>להתאמת תוכנית אימונים חשוב לנו לדעת מה המגדר שנולדת איתו</Typography>*/}
          {/*</Box>*/}
          <Box sx={{mt: "32px", display: "flex", justifyContent: "center"}}>
            <DoneSign/>
          </Box>
          <Box sx={{mt: "24px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", lineHeight: "26px", fontSize: "32px", fontWeight: 800, fontFamily: "Assistant"}}>תודה רבה!</Typography>
          </Box>
          <Box sx={{mt: "24px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", maxWidth: "70%", textAlign: "center",lineHeight: "26px", textWrap: "wrap", fontSize: "24px", fontWeight: 600, fontFamily: "Assistant"}}>להורדת האפליקציה</Typography>
          </Box>
          <Box sx={{mt: "24px", display: "flex", justifyContent: "center"}}>
            <Box onClick={onDownload} sx={{ml: "12px"}}>
              <GoogleIcon/>
            </Box>
            <Box onClick={() => window.open("https://apps.apple.com/il/app/progress-progressive-overload/id6450831926")}>
              <AppleIcon/>
            </Box>
          </Box>

          <Box sx={{mt: "24px", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <Box sx={{p: "10px", borderRadius: "8px", width: "340px", backgroundColor: "#232323"}}>
              <Typography sx={{color: "#FFF"}}>שם משתמש: <Typography display={"inline"} sx={{color: "#FFF"}}>{" " + userData.email}</Typography></Typography>
            </Box>
          </Box>

          <Box sx={{mt: "12px", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <Box sx={{p: "10px", borderRadius: "8px", width: "340px", backgroundColor: "#232323"}}>
              <Typography sx={{color: "#FFF"}}>סיסמה:<Typography display={"inline"} sx={{color: "#FFF"}}>{" " + userData.password}</Typography></Typography>

            </Box>
          </Box>
          <Box sx={{mt: "48px", display: "flex", justifyContent: "center"}}>
            <CrownFream/>
          </Box>
          {/*<Typography sx={{mr: "8px", maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#2EA08C", fontSize: "18px", fontWeight: 600, fontFamily: "Assistant"}}>תוכנית אימונים {userData.trainDays} פעמים בשבוע</Typography>*!/*/}
        </BackgroundPage>
      </Box>
  );
};

export default FinishScreen;
