import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import {ReactComponent as Male} from '../assets/male.svg';
import {ReactComponent as Female} from '../assets/female.svg';
import {ReactComponent as FemaleSelected} from '../assets/femaleSelected.svg';
import {ReactComponent as MaleSelected} from '../assets/maleSelected.svg';
import {useState} from "react";

const GenderPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [nextActive, setNextActive] = useState(userData.gender !== "");

  const genderChosen = (gender) => {
    setUserData(prv =>  {
      return {
        ...prv,
        gender: gender
      }
    });
    setNextActive(true);
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextActive={nextActive} nextPage={nextPage} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/genderPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>מגדר?</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>להתאמת תוכנית אימונים חשוב לנו לדעת מה המגדר שנולדת איתו</Typography>
          </Box>
          <Box onClick={() => genderChosen("MALE")} sx={{mt: "24px", display: "flex", justifyContent: "center"}}>
            {userData.gender === "MALE" ? <MaleSelected/> : <Male/>}
          </Box>
          <Box onClick={() => genderChosen("FEMALE")} sx={{mt : "24px", display: "flex", justifyContent: "center"}}>
            {userData.gender === "FEMALE" ? <FemaleSelected/> : <Female/>}
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default GenderPage;
