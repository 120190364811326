import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import OptionButton from "../components/OptionButton";
import {ReactComponent as SmallTime} from '../assets/smallTime.svg';
import {ReactComponent as SmallTimeActive} from '../assets/smallTimeActive.svg';

import {ReactComponent as MediumTime} from '../assets/mediumTime.svg';
import {ReactComponent as MediumTimeActive} from '../assets/mediumTimeActive.svg';

import {ReactComponent as LargeTime} from '../assets/largeTime.svg';
import {ReactComponent as LargeTimeActive} from '../assets/largeTimeActive.svg';
import {useState} from "react";

const TimePage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [nextActive, setNextActive] = useState(userData.trainTime !== "");

  const handleChange = (trainTime) => {
    setUserData(prv => {
      return {
        ...prv,
        trainTime: trainTime
      }
    });
    setNextActive(true);
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={nextActive} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/timePageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>כמה זמן אימון אתה יכול לבצע ?</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>חשוב לנו לדעת כמה זמן יש לך לאימון בשביל להתאים לך את תוכנית האימונים</Typography>
          </Box>
          <OptionButton onClick={() => handleChange("SMALL")} active={() => userData.trainTime === "SMALL"} icon={<SmallTime/>} activeIcon={<SmallTimeActive/>} text={"30-45 דק"}/>
          <OptionButton onClick={() => handleChange("MEDIUM")} active={() => userData.trainTime === "MEDIUM"} icon={<MediumTime/>} activeIcon={<MediumTimeActive/>} text={"45-60 דקות"}/>
          <OptionButton onClick={() => handleChange("LARGE")} active={() => userData.trainTime === "LARGE"} icon={<LargeTime/>} activeIcon={<LargeTimeActive/>} text={"60+ דקות"} />
        </BackgroundPage>
      </Box>
  );
};

export default TimePage;
