import {useState} from "react";
import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import OptionButton from "../components/OptionButton";
import {ReactComponent as FullBody} from "../assets/fullbody.svg";
import {ReactComponent as FullBodyActive} from "../assets/fullbodyActive.svg";
import {ReactComponent as Custom} from "../assets/custom.svg";
import {ReactComponent as CustomActive} from "../assets/customActive.svg";

const BodyPartPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [nextActive, setNextActive] = useState(userData.bodyPart !== "");

  const handleChange = (bodyPart) => {
    setUserData(prv => {
      return {
        ...prv,
        bodyPart: bodyPart,
        bodyPartSpecific: bodyPart === "FULL_BODY" ? [] : prv.bodyPartSpecific,
      }
    });
    setNextActive(true);
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={() => nextPage(userData.bodyPart === "FULL_BODY" ? {val: 2} : {val: 1})} nextActive={nextActive} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/timePageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>בחר את הדגש החשוב לך</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>תחליט על איזה חלק בגוף תרצה לשים דגש בתוכנית האימונים שלך</Typography>
          </Box>
          <OptionButton onClick={() => handleChange("FULL_BODY")} active={() => userData.bodyPart === "FULL_BODY"} icon={<FullBody/>} activeIcon={<FullBodyActive/>} text={"כל הגוף (מומלץ)"}/>
          <OptionButton onClick={() => handleChange("CUSTOM")} active={() => userData.bodyPart === "CUSTOM"} icon={<Custom/>} activeIcon={<CustomActive/>} text={"בהתאמה אישית"}/>
        </BackgroundPage>
      </Box>
  );
};

export default BodyPartPage;
