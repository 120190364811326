import {Box, Button, TextField, Typography} from "@mui/material";
import {useState} from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {RTL} from "../CacheRtl";
import validator from "validator";

const SignUpPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");

  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [emailNotMatch, setEmailNotMatch] = useState(false);
  const [passwordMin, setPasswordMin] = useState(false);


  const handleChange = () => {
    if (password !== confirmPassword || password === "" || confirmPassword === "") {
      setPasswordNotMatch(true);
      return;
    }

    if (!validator.isEmail(email) || email === "") {
      setEmailNotMatch(true);
      return;
    }

    if (password.length < 4) {
      setPasswordMin(true);
      return;
    }

    setUserData(prv => {
      return {
        ...prv,
        password: password,
        email: email
      }
    });

    nextPage(1);
  }

  const handleUserData = (value) => {
    setUserData(prv => {
      return {
        ...prv, name: value
      }
    });
  }

  const handleUserPhone = (value) => {
    setUserData(prv => {
      return {
        ...prv, phone: value
      }
    });
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100dvh",
              overflow: "hidden",
              maxHeight: "1024px",
              maxWidth: "100dvh",
              backgroundSize: "cover",
              backgroundImage: "url('images/erez1027.png')",
            }}>
          <Box sx={{position: "absolute",
            right: 0,
            bottom: "5%", }}>
            <Typography sx={{mr: "10%", width: "250px", color: "#F6F6F6",fontSize: "30px", fontWeight: 700}}>הרשמה</Typography>
            <Typography sx={{mt :"12px", mr: "10%", maxWidth: "80%", lineHeight: "32px", fontFamily: "Assistant", height: "104px", color: "#F6F6F6", fontSize: "18px", fontWeight: 600}}>ברוכים הבאים ל - PROGRESS</Typography>

            <Box sx={{mr: "10%", ml: "10%"}}>
            <RTL>
              <TextField
                  dir={"rtl"}
                  fullWidth
                  type={"name"}
                  value={userData.name}
                  onInput={() => setPasswordNotMatch(false)}
                  onChange={(e) => handleUserData(e.target.value)}
                  label="שם מלא"
                  variant={"standard"} sx={{input: { color: '#FFF'}, mt:"20px",
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInputLabel-standard': {
                  color: '#7A7A7A',
                }}}/>
              <TextField
                  dir={"rlt"}
                  fullWidth
                  type={"name"}
                  value={userData.phone}
                  onInput={() => setPasswordNotMatch(false)}
                  onChange={(e) => handleUserPhone(e.target.value)}
                  label="טלפון"
                  variant={"standard"} sx={{input: { color: '#FFF'}, mt:"20px",
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInputLabel-standard': {
                  color: '#7A7A7A',
                }}}/>
            <TextField
                dir={"ltr"}
                fullWidth
                onInput={() => setEmailNotMatch(false)}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="אימייל"
                variant={"standard"} sx={{input: { color: '#FFF'},
              '& .MuiInput-underline:before': {
                borderBottomColor: '#7A7A7A',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#7A7A7A',
              },
              '& .MuiInputLabel-standard': {
                color: '#7A7A7A',
              }}}/>
              <TextField
                  dir={"ltr"}
                  fullWidth
                  type={"password"}
                  value={password}
                  onInput={() => setPasswordNotMatch(false)}
                  onChange={(e) => setPassword(e.target.value)}
                  label="סיסמה"
                  variant={"standard"} sx={{input: { color: '#FFF'}, mt:"20px",
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInputLabel-standard': {
                  color: '#7A7A7A',
                }}}/>
              <TextField
                  dir={"ltr"}
                  fullWidth
                  type={"password"}
                  onInput={() => setPasswordNotMatch(false)}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="אימות סיסמה"
                  variant={"standard"} sx={{input: { color: '#FFF'}, mt:"20px",
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#7A7A7A',
                },
                '& .MuiInputLabel-standard': {
                  color: '#7A7A7A',
                }}}/>
            </RTL>
              {passwordNotMatch && <Typography color={"red"}>הסיסמאות לא תואמות</Typography>}
              {emailNotMatch && <Typography color={"red"}>אימייל לא תקין</Typography>}
              {passwordMin && <Typography color={"red"}>סיסמה חייבת להכיל לפחות 4 תווים</Typography>}
            </Box>
            <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Button
                  onClick={handleChange}
                  endIcon={<ArrowLeftIcon sx={{width: "32px", height: "24px", mt: "2px"}} />} sx={{borderRadius: "48px", fontSize: "17px", '&:hover': {
                  backgroundColor: "primary.light",
                }, backgroundColor: "primary.light", textAlign: "center", color: "#FFF", width: "310px"}}>המשך</Button>
            </Box>
          </Box>
        </Box>
      </Box>
  );
};

export default SignUpPage;
