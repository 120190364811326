import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import React, {useState} from "react";
import Picker from "rmc-picker/lib/Picker";
import Wheel from "../components/Wheel";
import "../components/Wheel.css";

let len = 150;
const HeightPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const onChange = (value) => {
    setUserData(prv => {
      return {
        ...prv, height: value > 0 ? value : 299 - Math.abs(value)
      }
    });
  };

  const getItems = (start) => {
    const items = [];
    for (let i = start; i < start + len; i++) {
      items.push(
          <Picker.Item value={i} key={i}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Typography sx={{color: "white", fontSize: "12px", alignSelf: "end"}}>cm</Typography>
              <Typography sx={{fontWeight: 600, fontSize: "16px", mr: "4px", color: "white"}}>{i}</Typography>
            </Box>
          </Picker.Item>
      );
    }
    return items;
  };
  const [items, setItems] = useState(getItems(100));

  function formateDate(_relative, absolute) {
    return _relative + 1;
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={true} percentage={percentage} prvPage={() => prvPage(userData.bodyPart === "FULL_BODY" ? {val: 2} : {val: 1})} backgroundImage={"/images/trainDaysPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>גובה?</Typography>
          </Box>
          <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div
                style={{
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#000",
                }}
            >
              <div style={{width: 150, height: "80%" }}>
                <Wheel
                    loop
                    initIdx={159}
                    length={300}
                    setValue={formateDate}
                    onChange={onChange}
                />
              </div>
            </div>
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default HeightPage;
