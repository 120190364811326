import {Box, Button, Hidden, Typography} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DialogProgress from "./DialogProgress";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const BackgroundPage = ({showProgress=true, nextActive, children, percentage, nextPage, prvPage, backgroundImage, disableButton=false, showBack=true}) => {

  return (
      <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100dvh",
            overflow: "hidden",
            maxHeight: "1024px",
            maxWidth: "100dvh",
            backgroundSize: "cover",
            backgroundImage: `url('${backgroundImage}')`,
          }}>
        <Box sx={{position: "absolute",
          right: "0",
          width: "100%",
          top: "5%", }}>
          {showProgress && <DialogProgress percentage={percentage}/>}
          {showBack && <Box>
            <ChevronRightIcon onClick={prvPage} sx={{cursor: "pointer", mt: "14px", color: "#FFF", width: "32px", height: "32px", mr: "7%"}}/>
          </Box>}
        {children}
        </Box>
        <Button
            disabled={!nextActive}
            onClick={nextPage}
            endIcon={<ArrowLeftIcon
                sx={{textAlign: "center", width: "32px", height: "24px", mt: "2px"}} />}
            sx={{borderRadius: "48px", fontSize: "17px", '&:hover': {
                backgroundColor: "primary.light",
              }, margin: "auto", display: disableButton ? "none" : "absolute", top: "90%", right: "35%",
              backgroundColor: !nextActive ? "#242424" : "primary.light", textAlign: "center", color: "#FFF", width: "116px"}}>
          <Typography sx={{mr: "12px"}}>הבא</Typography>
          </Button>
      </Box>
  );
};

export default BackgroundPage;
