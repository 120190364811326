import React, {useEffect, useRef, useState} from "react"
import { useKeenSlider } from "keen-slider/react"

export default function Wheel(props) {
  const perspective = props.perspective || "center"
  const wheelSize = 7
  const slides = props.length
  const slideDegree = 360 / wheelSize
  const slidesPerView = props.loop ? 7 : 1
  const [sliderState, setSliderState] = useState(null)
  const size = useRef(0)
  const options = useRef({
    slides: {
      number: slides,
      origin: props.loop ? "center" : "auto",
      perView: slidesPerView,
    },

    vertical: true,

    initial: props.initIdx || 0,
    loop: props.loop,
    dragSpeed: (val) => {
      const height = size.current
      return (
          val *
          (height /
              ((height / 2) * Math.tan(slideDegree * (Math.PI / 180))) /
              slidesPerView)
      )
    },
    slideChanged: (s) => {
      props.onChange(1 + s.track.details.abs % props.length)
    },
    created: (s) => {
      size.current = s.size
    },
    updated: (s) => {
      size.current = s.size
    },
    detailsChanged: (s) => {
      setSliderState(s.track.details)
    },
    rubberband: !props.loop,
    mode: "free-snap",
  })

  const [sliderRef, slider] = useKeenSlider(options.current)

  const [radius, setRadius] = React.useState(0)

  useEffect(() => {
    if (slider.current) setRadius(slider.current.size / 2)
  }, [slider])

  function slideValues() {
    if (!sliderState) return []
    const offset = props.loop ? 1 / 2 - 1 / slidesPerView / 2 : 0

    const values = []
    for (let i = 0; i < slides; i++) {
      const distance = sliderState
          ? (sliderState.slides[i].distance - offset) * slidesPerView
          : 0
      const rotate =
          Math.abs(distance) > wheelSize / 2
              ? 180
              : distance * (360 / wheelSize) * -1
      const style = {
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
        WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
      }
      const value = props.setValue
          ? props.setValue(i, sliderState.abs + Math.round(distance))
          : i
      values.push({ style, value })
    }
    return values
  }

  return (
      <div
          className={"wheel keen-slider wheel--perspective-" + perspective}
          ref={sliderRef}
      >
        <div
            className="wheel__shadow-top"
            style={{
              transform: `translateZ(${radius}px)`,
              WebkitTransform: `translateZ(${radius}px)`,
            }}
        />
        <div className="wheel__inner">
          <div className="wheel__slides" style={{ width: props.width + "px" }}>
            {slideValues().map(({ style, value }, idx) => (
                <div className="wheel__slide" style={style} key={idx}>
                  <span style={{paddingTop: "10px"}}>{value}</span>
                </div>
            ))}
          </div>
          {props.label && (
              <div
                  className="wheel__label"
                  style={{
                    transform: `translateZ(${radius}px)`,
                    WebkitTransform: `translateZ(${radius}px)`,
                  }}
              >
                {props.label}
              </div>
          )}
        </div>
        <div
            className="wheel__shadow-bottom"
            style={{
              transform: `translateZ(${radius}px)`,
              WebkitTransform: `translateZ(${radius}px)`,
            }}
        />
      </div>
  )
}
