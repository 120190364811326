import {Box, Button, Typography} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {ReactComponent as ProgresIconIcon} from '../assets/icon_progress.svg';
import {ReactComponent as IconText} from '../assets/iconText.svg';

const WelcomePage = ({nextPage}) => {
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100dvh",
              overflow: "hidden",
              maxHeight: "1024px",
              maxWidth: "100dvh",
              backgroundSize: "cover",
              backgroundImage: "url('images/erez1027.png')",
            }}>
          <Box sx={{mt: "12px", mr: "12px"}}>
          <ProgresIconIcon/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", mt: "30%"}}>
          <IconText/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{
              mt: "16px",
              lineHeight: "32px",
              fontFamily: "Assistant",
              color: "#F6F6F6",
              fontSize: "20px",
              fontWeight: 700}}>בלעדי ללקוחות אייקון חדרה!</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{
              lineHeight: "32px",
              fontFamily: "Assistant",
              color: "#F6F6F6",
              fontSize: "18px",
              fontWeight: 600}}>מאמן הכושר החכם שלך! אנחנו כאן כדי לעזור</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{
              lineHeight: "32px",
              fontFamily: "Assistant",
              color: "#F6F6F6",
              fontSize: "18px",
              fontWeight: 600}}> לך להשיג את יעדי הכושר שלך.</Typography>
          </Box>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Button
                onClick={nextPage}
                endIcon={<ArrowLeftIcon sx={{mt: "2px", width: "32px", height: "24px"}} />} sx={{mt: "5%", borderRadius: "48px", fontSize: "17px", '&:hover': {
                backgroundColor: "primary.light",
              }, backgroundColor: "primary.light", textAlign: "center", color: "#FFF", width: "310px"}}>התחל עכשיו</Button>
          </Box>
          <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", fontSize: "16px", fontWeight: 700, fontFamily: "Assistant"}}>יש לך משתמש?</Typography>
            <Typography onClick={() => window.open("https://apps.apple.com/il/app/progress-progressive-overload/id6450831926", "_blank")} sx={{cursor: "pointer", textDecorationLine: "underline", mr: "4px", color: "#07BBA6", fontSize: "16px", fontWeight: 700, fontFamily: "Assistant"}}>התחבר</Typography>
          </Box>
          <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography sx={{color: "#C0C0C0", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>בלחיצה על התחל אתה גם מסכים עם</Typography>
          </Box>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography onClick={() => window.open("https://progress-workout.com/privacy-policy-terms-conditions", "_blank")} sx={{cursor: "pointer", textDecorationLine: "underline", mr: "4px", color: "#07BBA6", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>תנאי שימוש</Typography>
          </Box>
        </Box>
      </Box>
  )
};

export default WelcomePage;
