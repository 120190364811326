import {Box, TextField, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";

const ClubPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {


  const handleChange = (club) => {
    setUserData(prv => {
      return {
        ...prv,
        club: club
      }
    });
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={userData.club?.length === 9} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/clubPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>הכנס את תעודת הזהות שלך</Typography>
          </Box>
          <Box sx={{mt: "14px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", textAlign: "center", textWrap: "wrap",  color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>בכדי להתחיל להשתמש באפליקציה אנו צריכים לאמת את הזהות שלך</Typography>
          </Box>
          <Box sx={{mt :"50px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <TextField
                onChange={ (e) => {handleChange(e.target.value)}}
                // onInput = {(e) =>{
                //   e.target.value = Math.max(0, (e.target.value).toString().length).toString().slice(0,9)
                // }}
                type={"number"} sx={{width: "80%", backgroundColor: "#CCCCCC"}}></TextField>
          </Box>
        </BackgroundPage>
      </Box>
  );
};

export default ClubPage;
