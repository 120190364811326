import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";
import OptionButton from "../components/OptionButton";
import {ReactComponent as Muscle} from '../assets/muscle.svg';
import {ReactComponent as BurnFat} from '../assets/burnFat.svg';
import {ReactComponent as Strength} from '../assets/strenth.svg';
import {ReactComponent as Health} from '../assets/health.svg';
import {ReactComponent as MuscleActive} from '../assets/muscleActive.svg';
import {ReactComponent as BurnFatActive} from '../assets/burnFatActive.svg';
import {ReactComponent as StrengthActive} from '../assets/strenthActive.svg';
import {ReactComponent as HealthActive} from '../assets/healthActive.svg';

const TargetPage = ({nextPage, setUserData, userData, prvPage, percentage}) => {
  function findArrayElementByTitle(array, title) {
    return array.some(item => item === title);
  }

  const modifyTargets = (target) => {
    setUserData(prv => {
      let newTargets = [...prv.targets];

      if (findArrayElementByTitle(newTargets, target)){
        newTargets = newTargets.filter((item) => item !== target);
      } else {
        newTargets.push(target);
      }

      return {
        ...prv,
        targets: newTargets
      }
    })
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={nextPage} nextActive={userData.targets.length > 0} percentage={percentage} prvPage={prvPage} backgroundImage={"/images/targetPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>מהי המטרה שלך?</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>חשוב לנו לדעת מה המטרה שלך בשביל להתאים לך את תוכנית האימונים</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#EDEDED", fontSize: "14px", fontWeight: 600, fontFamily: "Assistant"}}>(ניתן לבצע בחירות מרובות)</Typography>
          </Box>
          <OptionButton onClick={() => modifyTargets("MUSCLE")} active={() => findArrayElementByTitle(userData.targets, "MUSCLE")} icon={<Muscle/>} activeIcon={<MuscleActive/>} text={"עלייה במסת השריר"}/>
          <OptionButton onClick={() => modifyTargets("BURN_FAT")} active={() => findArrayElementByTitle(userData.targets, "BURN_FAT")}  icon={<BurnFat/>} activeIcon={<BurnFatActive/>} text={"ירידה האחוזי השומן"}/>
          <OptionButton onClick={() => modifyTargets("HEALTH")} active={() => findArrayElementByTitle(userData.targets, "HEALTH")}  icon={<Health/>} activeIcon={<HealthActive/>} text={"שיפור בריאות כללי"} />
          <OptionButton onClick={() => modifyTargets("STRENGTH")} active={() => findArrayElementByTitle(userData.targets, "STRENGTH")}  icon={<Strength/>} activeIcon={<StrengthActive/>} text={"שיפור כוח"}/>
        </BackgroundPage>
      </Box>
  );
};

export default TargetPage;
