import {Box, Typography} from "@mui/material";

const OptionButton = ({onClick, active, icon, activeIcon, text, width = "70%", height = "46px"}) => {
  return (
      <Box onClick={onClick}
          sx={{border: "1px solid #4A4A4A",
        cursor: "pointer",
        borderRadius: "55px", mt :"14px",
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "start",
        alignItems: "center",
        display: "flex", padding: "10px", width: {width}, height: {height}, backgroundColor: active() ? "#08A693" : "#242424"}}>
        <Typography sx={{fontSize: "16px", fontWeight: 600, textAlign: "center", flex: 9, color: "#FFF"}}>{text}</Typography>
        <Box sx={{ml: "14px", textAlign: "end", flex: 1}}>
          {active() ? activeIcon : icon}
        </Box>
      </Box>
  );
};

export default OptionButton;
