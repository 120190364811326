import './App.css';
import {ThemeProvider} from "@mui/material";
import "@fontsource/assistant"; // Defaults to weight 400
import "@fontsource/assistant/400.css"; // Specify weight
import theme from "./theme";
import WelcomePage from "./pages/WelcomePage";
import {useState} from "react";
import GenderPage from "./pages/GenderPage";
import TargetPage from "./pages/TargetPage";
import TrainDaysPage from "./pages/TrainDaysPage";
import TimePage from "./pages/TimePage";
import ClubPage from "./pages/ClubPage";
import ExperiencePage from "./pages/ExperiencePage";
import BodyPartPage from "./pages/BodyPartPage";
import BodyPartSpecificPage from "./pages/BodyPartSpecificPage";
import HeightPage from "./pages/HeightPage";
import WeightPage from "./pages/WeightPage";
import DatePage from "./pages/DatePage";
import LoadingPage from "./pages/LoadingPage";
import SignUpPage from "./pages/SignUpPage";
import ProgramReadyPage from "./pages/ProgramReadyPage";
import FinishScreen from "./pages/FinishScreen";
import ErrorPage from "./pages/ErrorPage";

const App =() => {
  const [page, setPage] = useState(0);

  const [userData, setUserData] = useState({
    gender: "",
    companyId: process.env.REACT_APP_COMPANY_ID,
    trainerId: process.env.REACT_APP_TRAINER_ID,
    targets: [],
    trainDays: 3,
    trainTime: "",
    name: "",
    phone: "",
    club: "",
    experience: "",
    bodyPart: "",
    bodyPartSpecific: [],
    height: 170,
    weight: 70,
  });

    const nextPage = ({val = 1}) => {
      console.log(val);
    setPage(prv => prv + val);
  }

  const prvPage = ({val = 1}) => {
    setPage(prv => prv - val);
  }

  return (
      <ThemeProvider theme={theme}>
        {page === -1 && <ErrorPage />}
        {page === 0 && <WelcomePage nextPage={nextPage}/>}
        {page === 1 && <SignUpPage userData={userData} setUserData={setUserData} percentage={"95%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 2 && <ClubPage userData={userData} setUserData={setUserData} percentage={"50%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 3 && <GenderPage userData={userData} setUserData={setUserData} percentage={"10%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 4 && <TargetPage userData={userData} setUserData={setUserData} percentage={"20%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 5 && <TrainDaysPage userData={userData} setUserData={setUserData} percentage={"30%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 6 && <TimePage userData={userData} setUserData={setUserData} percentage={"40%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 7 && <ExperiencePage userData={userData} setUserData={setUserData} percentage={"60%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 8 && <BodyPartPage userData={userData} setUserData={setUserData} percentage={"70%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 9 && <BodyPartSpecificPage userData={userData} setUserData={setUserData} percentage={"75%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 10 && <HeightPage userData={userData} setUserData={setUserData} percentage={"80%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 11 && <WeightPage userData={userData} setUserData={setUserData} percentage={"90%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 12 && <DatePage userData={userData} setUserData={setUserData} percentage={"95%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 13 && <LoadingPage setPage={setPage} userData={userData} setUserData={setUserData} percentage={"95%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 14 && <ProgramReadyPage userData={userData} percentage={"100%"} prvPage={prvPage} nextPage={nextPage} />}
        {page === 15 && <FinishScreen userData={userData} />}
      </ThemeProvider>
  );
}

export default App;
