import React, {useState} from "react"
import {format, subDays, subMonths, subYears} from "date-fns"
import Wheel from "../components/Wheel"
import "../components/Wheel.css"
import BackgroundPage from "../components/BackgroundPage";
import {Box, Typography} from "@mui/material";

const DatePage = ({nextPage, setUserData, userData, prvPage, percentage}) => {

  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2000);


  const handleYearChange = (value) => {
    setYear(value > 0 ? 1899 + value : 2023 - Math.abs(value));
  }

  const handleDayChange = (value) => {
    setDay(value > 0 ? value : 31 - Math.abs(value));
  }

  const handleMonthChange = (value) => {
    setMonth(value > 0 ? value : 12 - Math.abs(value));
  }

  function generateYears(_relative, absolute) {
    return 1900 + _relative
  }

  function generateDays(_relative, absolute) {
    return _relative +1
  }

  function generateMonths(_relative, absolute) {
    return ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"].at(_relative)
  }

  const handleNext = () => {
    setUserData(prv => {
      return {
        ...prv, birthDate: new Date(year, month, day).getTime()
      }
    });
    nextPage(1);
  }

  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage nextPage={handleNext} nextActive={true} percentage={percentage} prvPage={() => prvPage(userData.bodyPart === "FULL_BODY" ? {val: 2} : {val: 1})} backgroundImage={"/images/trainDaysPageImage.png"}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{maxWidth: "80%", textAlign: "center", textWrap: "wrap", color: "#FFF", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>תאריך לידה ?</Typography>
          </Box>
          <Box sx={{mt: "40px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div
                style={{
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#000",
                }}
            >
              <div style={{width: 100, height: "80%" }}>
                <Wheel
                    loop
                    length={124}
                    width={140}
                    perspective="right"
                    setValue={generateYears}
                    onChange={handleYearChange}
                />
              </div>
              <div style={{width: 100, height: "80%" }}>
                <Wheel loop
                       setValue={generateMonths}
                       onChange={handleMonthChange}
                       length={12}
                       width={23}
                       perspective="center"
                />
              </div>
              <div style={{width: 100, height: "80%" }}>
                <Wheel loop
                       length={31}
                       width={23}
                       perspective="left"
                       setValue={generateDays}
                       onChange={handleDayChange}
                />
              </div>

            </div>
          </Box>
        </BackgroundPage>
      </Box>
  )
};

export default DatePage;
