import {Box} from "@mui/material";

const DialogProgress = ({percentage}) => {
  return (
      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{display: "relative", borderRadius: "7px", backgroundColor: "#424242", width: "80%", height: "6px"}}>
          <Box sx={{display: "absolute", borderRadius: "7px", backgroundColor: "#fff", width: percentage, height: "6px"}}/>
        </Box>
      </Box>
  );
};

export default DialogProgress;
