import {Box, Typography} from "@mui/material";
import BackgroundPage from "../components/BackgroundPage";

const ErrorPage = () => {
  return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <BackgroundPage showBack={false} nextActive={false} percentage={"100%"} backgroundImage={"/images/genderPageImage.png"}>
          <Box sx={{mt: "36px", display: "flex", justifyContent: "center"}}>
            <Typography sx={{color: "#da6363", fontSize: "21px", fontWeight: 700, fontFamily: "Assistant"}}>אופס :(</Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <Typography sx={{maxWidth: "70%", color: "#F9F9F9", fontSize: "16px", fontWeight: 600, fontFamily: "Assistant"}}>ההרשמה נכשלה, נא לשלוח הודעה למנהל המערכת או נסה שוב</Typography>
          </Box>
          </BackgroundPage>
      </Box>
  )
};

export default ErrorPage;
